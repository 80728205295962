import React, { useEffect, useState,useRef,useCallback,useMemo } from "react";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
//import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import { useDispatch, useSelector } from "react-redux";
import { setConversations,deleteUsermessage } from "../../../redux/apiCalls";
import {io} from "socket.io-client";
import axios from "axios";

const Index = ({ isChannel }) => {

  const { selectedChat,
    selectedGroupChat,
    selectedUserMessage,
      chatUserDetails,
      chatUserConversations,
      isUserMessageSent,
      isMessageDeleted,
      isMessageForwarded,
      isUserMessagesDeleted,
      isImageDeleted,
      currentUser:user      
   } = useSelector((state) => state.user);

  const dispatch = useDispatch();


   
   const [conversations , setConversations] = useState([]);
   const [currentChat, setCurrentChat] = useState(null);
   const [messages, setMessages] = useState([]);
   const [newMessage, setNewMessage] = useState();
   const [reply,setReply] = useState([]);
   const [onlineUsers, setOnlineUsers] = useState({});
   const [arriavalMessage, setArrivalMessage] = useState(null);
   const [receiverId, setReceiverid] = useState({});

   const [deleteid, setDeleteid] = useState(false);
   const [converstationInsert, setConversationInsert] = useState(false);

   const chatref = useRef(false);
   //Socket Code Start
   const socket = useRef();

   useEffect(() =>{   
    //socket.current = io.connect('wss://osschat.in:8900');
   	
   socket.current = io.connect('wss://osschat.in:8900', {
      transports: ['websocket'], // Ensure we're using the WebSocket transport
      reconnectionAttempts: 5,    // Retry connection attempts      	
      reconnectionDelayMax: 10000, // Max reconnection delay if socket disconnects
    });
   
      socket.current.on("getMessage", data =>{
            setArrivalMessage({
                sender: data.senderId,
                text: data.text,
                createdAt: Date.now(),
            });
        });
   
   // Cleanup on component unmount
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
   
   
},[]);

//for socket connected 
useEffect(()=>{
    socket.current.emit("addUser", user._id);      
      socket.current.on("getUsers",users=>{
        user.followings?.filter(f=>users.some(u=>u.userId === f));
        setOnlineUsers(users);
        console.log("socketUsers",users)         
      })    
},[user._id])

   //Socket Code End
 
   


   const getConversations = async () => { 
     if(selectedGroupChat !== null ){
      const res = await axios.get("/conversations/groupfind/"+selectedChat+'/'+user._id);
      console.log('getConversationIf',res);
      setConversations(res)
     }else{
       if(selectedChat !== null ) {
        const res = await axios.get("/conversations/find/"+selectedChat+'/'+user._id).then((res)=>{
            if(res.data === null){
              insertConversations();
            } else if(res.data !== null){
              console.log('getConversationElse',res.data);        
              setConversations(res)
            }
        });
       
        //res.data? setConversations(res.data) : insertConversessionUser(selectedChat,user._id);
      
       }
     }    
    //setConversations(dispatch,res); 
   };

   const insertConversations = async () => { 
    if(selectedChat !== null ){
      const senderId = selectedChat;
      const receiverId = user?._id;
     const res = await axios.post("/conversations/"+senderId+'/'+receiverId);
     console.log('insertConversation',res);
     setConversations(res);
    } 
  };

  useEffect(() => {
    getConversations();
  }, [selectedChat]);

  // useEffect(() => {
  //   const hasComponentRendered = localStorage.getItem('componentRendered');

  //   if (!hasComponentRendered) {
  //     // Perform any necessary actions that should be executed only once
  //     // For example, insert conversations
  //     if(conversations.length = 0){
  //       insertConversations();
  //     }
      
  //     // Set the flag in localStorage to indicate that the component has rendered
  //     localStorage.setItem('componentRendered', true);
  //   }

  //   // Clean up the localStorage flag on component unmount
  //   return () => {
  //     localStorage.removeItem('componentRendered');
  //   };
  // }, []);


    // if(chatref.current === false){
    //   if(conversations.length == 0){
    //     console.log('insertconvert')
    //       insertConversations();
    //       setConversationInsert(true);          
    //   }
    //   chatref.current = true;
    // }




const conversationId = conversations._id;

//First time load all messges via this API
const getMessages = async () => {
  const res = await axios.get("/messages/" + conversationId);    
    setCurrentChat(res)           
    setMessages(res)
    //setReply(res)  
};

useEffect(() => {
getMessages();
},[conversationId])

//When I submit messages then hit this API
const getCurrentMessages = async () => {
  const res = await axios.get("/messages/" + conversationId);
    setMessages(res)
    //setReply(res)  
};

useMemo(() => {
  getCurrentMessages();
},[newMessage])

useEffect(()=>{
  arriavalMessage && conversations?.members.includes(arriavalMessage.sender) && 
  setMessages((prev) =>[...prev,arriavalMessage])
},[arriavalMessage, conversations]);




const onDeleteMessage = async (messageId) => {
  console.log('messagId',messageId);
  //setDeleteid(messageId)
  console.log('chatuserId',chatUserDetails?._id)
  const data = { mid: messageId, userId: chatUserDetails?._id }
  const res = await axios.delete("/messages/user/"+ data?.mid);
  //setDeleteid(res)
  deleteUsermessage(dispatch,data);
  //dispatch(deleteMessage(chatUserDetails._id, messageId));
};

useEffect( async ()=> {
  if(selectedUserMessage !== null){
    setDeleteid(true);
      setTimeout(() => {
        getMessages();
      }, 1000);
  }
 },[deleteid,conversationId,selectedUserMessage])


  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
 // const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState();

  const onSetReplyData = (reply) => {
    console.log('reply',reply)
    setReplyData(reply);
  };

  /*
  send message
  */

  const [image, setImage] = useState(null);

  const onSend = async (data) => {

    console.log('onSend',data);
    const imageData = data.image;

    console.log('imageData',imageData)   
  
    let Dataimage = [];
            
            imageData.map((img,index) => {
              const newdata = new FormData();
              const fileName = Date.now() + img.name;
              newdata.append("file",img);
              newdata.append("name",fileName);
              newdata.img = img.name;            
              console.log('fileName',fileName);    
              Dataimage.push(newdata);              
            })

      console.log('Dataimage',Dataimage)

            setImage(Dataimage);
            
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    console.log("length",newMessage);

   console.log('Newimage',image); 


    
    if(selectedGroupChat !== null ){
      const receiveId = conversations.members.find(
          (member) => member !== user._id
      );
      setReceiverid(receiveId);
    }else{
      const receiveId = conversations.members.find(
        (member) => member !== user._id
      );
      setReceiverid(receiveId);
    }

    let DataImage = [];

    if(image && image !== null) {
      image.map( async (im,index) => {
        await axios.post("/upload",im);
      })
           
      image.map((im,index)=>{
        DataImage.push(im.img);
     });
      
        // const params = {
        //   name: image? image.image.name : '',
        //   type: image? image.image.type : '',
        //   img: image? image.image.img : null,
        // }
        //params["image"] = image;
    }

    if (replyData && replyData !== null) {     
      
      const params = {
        text: replyData? replyData.text : newMessage,
        time: new Date().toISOString(),
        //image: data.image && data.image,
       // attachments: data.attachments && data.attachments,
        meta: {
          receiver: chatUserDetails._id,
          sender: replyData.sender,
        },
      };

      params["replyOf"] = replyData;
      setReply(params);      
    }

    console.log('params',reply)    

    if(newMessage === ''){
        console.log("Blank Msg")
        return false;
    }
    
   

    const message = {
        sender: user._id,
       // name:image? image.image.name : '',
       // type:image? image.image.type: '',
        img:  image? DataImage: [],        
        text:newMessage,
        replyOf: replyData? replyData.text : '',
        reply: replyData? true : false,
        conversationId: conversations._id
    }; 

    console.log('messageImage',message)

    socket.current.emit("sendMessage", {
        senderId: user._id,
        receiverId,
        text: newMessage,
    })

    try{
        const img = await axios.post("/upload",message);
        const res = await axios.post("/messages",message);       
        setMessages([...messages, res])
        onSetReplyData(null);
        setNewMessage('')
    }catch(err){
        console.log(err)
    }   
};


  // const onSend = (data) => {
  //   let params = {
  //     text: data.text && data.text,
  //     time: new Date().toISOString(),
  //     image: data.image && data.image,
  //     attachments: data.attachments && data.attachments,
  //     meta: {
  //       receiver: chatUserDetails.id,
  //       sender: userProfile.uid,
  //     },
  //   };
  //   if (replyData && replyData !== null) {
  //     params["replyOf"] = replyData;
  //   }

  //   dispatch(onSendMessage(params));
  //   if (!isChannel) {
  //     setTimeout(() => {
  //       dispatch(receiveMessage(chatUserDetails._id));
  //     }, 1000);
  //     setTimeout(() => {
  //       dispatch(readMessage(chatUserDetails._id));
  //     }, 1500);
  //     setTimeout(() => {
  //       dispatch(receiveMessageFromUser(chatUserDetails._id));
  //     }, 2000);
  //   }
  //   setReplyData(null);
  // };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      dispatch(getChatUserConversations(chatUserDetails._id));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  ]); 

  // useEffect(() => {
  //   window.addEventListener('mousemove', () => {});
  
  //   // returned function will be called on component unmount 
  //   return () => {
  //     window.removeEventListener('mousemove', () => {})
  //   }
  // }, [])


  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails._id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails._id));
  };
  

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        selectedChat={selectedChat}
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        setDeleteid={setDeleteid}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
        messages={messages}
        params={reply}
        conversations={conversations}
            
      />
      <ChatInputSection
      handleSubmit={handleSubmit}      
      setNewMessage={setNewMessage}
      newMessage={newMessage}      
      onSend={onSend}
      replyData={replyData}
      onSetReplyData={onSetReplyData}
      chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
